<template>
  <div class="card product-list-content-item" style="box-shadow: none">
    <!-- <div class="row justify-content-between align-items-center">
      <div class="col-9">
        <div class="row align-items-center">
          <div
            :class="type === 'SELLER' ? 'col-4' : 'col-5'"
            class="col-4 d-flex"
          >
            <div v-if="type === 'SELLER'" class="item-img">
              <img
                :src="this.CDN_URL + item.images.find((x) => x) + '?size=46x46'"
                alt
              />
            </div>
            <div>
              <h3 class="item-name">
                {{ item.contents.find((x) => x.default === true).name }}
              </h3>
              <p v-if="type === 'BUYER'" class="item-about">
                {{ item.contents.find((x) => x.default === true).description }}
              </p>
              <div v-if="type === 'SELLER'" class="item-id">
                Product ID: {{ item.product_id }}
              </div>
            </div>
          </div>
          <div v-if="type === 'SELLER'" class="col-4">
            <p class="item-desc">
              {{ item.contents.find((x) => x.default === true).description }}
            </p>
          </div>
          <div :class="type === 'SELLER' ? 'col-1' : 'col-2'">
            <h3 class="item-unit text-right p-0 text-capitalize">
              {{ item.unit.shortname }}
            </h3>
          </div>
          <div v-if="type === 'SELLER'" class="col-3">
            <h3 class="text-right p-0">
              {{ item.currency.symbol }}
              {{
                item.min_price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              }}
              -
              {{
                item.max_price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              }}
            </h3>
          </div>
          <div v-if="type === 'BUYER'" class="col-2">
            <h3 class="text-right p-0">{{ item.demand }}</h3>
          </div>
          <div v-if="type === 'BUYER'" class="col-3">
            <div
              class="d-flex align-items-center justify-content-start"
              style="margin-left: 35px"
            >
              <span class="item-icon onboarding-industry-icon d-block mr-2">
                <industry-icon :color="'primary'" :name="item.industry.icon" />
              </span>
              <span class="industry-name">{{ item.industry.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2" v-if="type === 'BUYER'">
        <div class="d-flex justify-content-between">
          <router-link
            :to="'/register-steps/step-3/inquiry-edit/' + item.id"
            class="item-link edit"
            >Edit</router-link
          >
          <a
            @click.prevent="deleteItem(item.id)"
            href="#"
            class="item-link delete"
            >Delete</a
          >
        </div>
      </div>
      <div class="col-2" v-if="type === 'SELLER'">
        <div class="d-flex justify-content-between">
          <router-link
            :to="'/register-steps/step-3/product-edit/' + item.id"
            class="item-link edit"
            >Edit</router-link
          >
          <a
            @click.prevent="deleteItem(item.id)"
            href="#"
            class="item-link delete"
            >Delete</a
          >
        </div>
      </div>
    </div> -->

    <b-row v-if="type === 'SELLER'">
      <b-col cols="12">
        <div class="accordion" role="tablist" id="listViewAccordion">
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 list-view-headers"
              role="tab"
            >
              <b-button
                block
                v-b-toggle="'collapse' + item.id"
                variant="info"
                class="list-view-button"
                style="background: none !important"
              >
                <b-row align-v="center" id="member-table-list">
                  <b-col cols="3">
                    <b-row>
                      <b-col cols="2">
                        <img
                          :src="CDN_IMAGE_PREFIX + $_.first(item.images) + '?size=46x46'"
                          width="46"
                          height="46"
                          class="mr-3"
                          alt=""
                        />
                      </b-col>
                      <b-col cols="10" class="pl-5">
                        <h4>
                          {{ getDefault(item.contents).name }}
                        </h4>
                        <h6 class="mb-0">
                          {{ $t('crt-quote.step3.label-2') }}:
                          {{ item.product_id }}
                        </h6>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2">
                    <h6>
                      {{ getDefault(item.contents).description }}
                    </h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="ctr-black-color">
                      {{ item.currency.symbol }}
                      {{
                        item.min_price
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}
                      -
                      {{
                        item.max_price
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                      }}

                      <span class="member-color-grey"
                        >/ {{ item.unit.shortname }}</span
                      >
                    </h6>
                  </b-col>
                  <b-col cols="1">
                    <h6 class="ctr-black-color">
                      {{numberFixer(item.moq) }}
                      <span class="member-color-grey">{{
                        item.unit.shortname
                      }}</span>
                    </h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="ctr-black-color">
                      {{ numberFixer(item.stock) }}
                      <span class="member-color-grey"
                        >/ {{ item.unit.shortname }}</span
                      >
                    </h6>
                  </b-col>
                  <b-col cols="2" class="text-right d-flex">
                    <a
                      href="#"
                      v-b-toggle="'collapse' + item.id"
                      style="margin-left: 40px"
                    >
                      <img :src="eye" class="img-fluid w-22" alt="" />
                    </a>
                    <router-link
                      style="margin-left: 40px"
                      :to="'/register-steps/step-3/product-edit/' + item.id"
                      class="item-link edit"
                    >
                      <img :src="editBlue" class="img-fluid w-22" alt="" />
                    </router-link>
                    <a
                      href="#"
                      @click.prevent="deleteItem(item.id)"
                      style="margin-left: 40px"
                    >
                      <img :src="binRed" class="img-fluid w-22" alt="" />
                    </a>
                  </b-col>
                </b-row>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'collapse' + item.id"
              :visible="false"
              accordion="my-accordion"
              role="tabpanel"
              class="collapse-requests"
            >
              <b-card-body>
                <b-card-text>
                  <b-row>
                    <b-col cols="3">
                      <b-carousel
                        id="carousel-no-animation"
                        style="text-shadow: 0px 0px 2px #000; border-radius:6px !important;"
                        no-animation
                        indicators
                        img-width="300"
                        img-height="220"
                      >
                        <b-carousel-slide
                          :img-src="
                            CDN_IMAGE_PREFIX +
                              item.images.find(x => x) +
                              '?size=247x247'
                          "
                        ></b-carousel-slide>
                      </b-carousel>
                    </b-col>
                    <b-col cols="9">
                      <!-- <div class="member-view-close-button">
                        <a href="">>{{ $t('buttons.close') }}</a>
                      </div> -->
                      <b-row>
                        <b-col cols="10" class="mb-1">
                          <h1>
                            {{
                              item.contents.find(x => x.default === true).name
                            }}
                          </h1>
                          <p class="mb-0">
                            >{{ $t('crt-quote.step3.label-2') }}:
                            {{ item.product_id }}
                          </p>
                          <p class="mb-35">
                            {{
                              item.contents.find(x => x.default === true)
                                .description
                            }}
                          </p>
                        </b-col>
                      </b-row>

                      <b-row style="position: absolute; bottom: 0; width: 100%">
                        <b-col cols="12">
                          <b-row>
                            <b-col cols="5">
                              <p class="quotations-column-content mb-0">
                                {{ $t('myquotes.ppu') }}
                                <span
                                  >{{ item.currency.symbol }}
                                  {{
                                    item.min_price
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                  }}
                                  -
                                  {{
                                    item.max_price
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                  }}</span
                                >
                                <span class="ton-color"
                                  >/ {{ item.unit.shortname }}</span
                                >
                              </p>
                            </b-col>

                            <b-col cols="3">
                              <p class="quotations-column-content mb-0">
                                {{ $t('myquotes.moq') }}
                                <span> {{ numberFixer(item.moq) }}</span>
                                <span
                                  class="ton-color"
                                  style="color: #a7a7a7 !important"
                                  >/ {{ item.unit.shortname }}</span
                                >
                              </p>
                            </b-col>

                            <b-col cols="4">
                              <p class="quotations-column-content mb-0">
                                {{ $t('myquotes.capacity') }}
                                <span>{{numberFixer(item.stock) }}</span>
                                <span
                                  class="ton-color"
                                  style="color: #a7a7a7 !important"
                                  >/ {{ item.unit.shortname }}</span
                                >
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="12">
        <div class="accordion" role="tablist" id="listViewAccordion">
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1 list-view-headers"
              role="tab"
            >
              <b-button
                block
                v-b-toggle="'collapse' + item.id"
                variant="info"
                class="list-view-button"
                aria-expanded="false"
              >
                <b-row
                  align-v="center"
                  id="member-table-list"
                  class="member-table-list__r3x"
                  aria-expanded="false"
                  style="background-color: hsla(0,0%, 97.3%, .8);"
                >
                  <b-col cols="4">
                    <h4>
                      {{ getDefault(item.contents).name }}
                    </h4>
                    <h6 >
                      {{ getDefault(item.contents).description }}
                    </h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="member-view-color">{{ item.unit.name }}</h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="member-view-color float-left">
                      {{ numberFixer(item.demand) }}
                    </h6>
                    <h6 class="float-left ml-2">
                      {{ item.unit.shortname }}
                    </h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="member-view-color float-left">
                      <!--<img src="/img/industry-icon.svg" alt="" /> -->
                      <industry-icon
                        :color="'primary'"
                        :name="item.industry.icon"
                      />
                    </h6>
                    <h6 class="ml-3 float-left">{{ item.industry.name }}</h6>
                  </b-col>
                  <b-col cols="2" class="text-right d-flex">
                    <a href="#" style="margin-left: 40px">
                      <img :src="eye" class="signin-item-icons" alt="" />
                    </a>
                    <router-link
                      style="margin-left: 40px"
                      :to="'/register-steps/step-3/inquiry-edit/' + item.id"
                      class="item-link edit"
                    >
                      <img :src="editBlue" class="signin-item-icons" alt="" />
                    </router-link>
                    <a
                      href="#"
                      @click.prevent="deleteItem(item.id)"
                      style="margin-left: 40px"
                    >
                      <img :src="binRed" class="signin-item-icons" alt="" />
                    </a>
                  </b-col>
                </b-row>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'collapse' + item.id"
              :visible="false"
              accordion="my-accordion"
              role="tabpanel"
              class="collapse-requests"
            >
              <b-card-body>
                <b-card-text>
                  <h1>
                    {{ item.contents.find(x => x.default === true).name }}
                  </h1>
                  <b-row>
                    <b-col cols="8" class="mb-5">
                      <p>
                        {{
                          item.contents.find(x => x.default === true)
                            .description
                        }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <p>
                        <img src="/img/industry-icon.svg" alt="" />
                        {{ item.industry.name }}
                      </p>
                    </b-col>

                    <b-col cols="2">
                      <p>
                        {{ $t('myrequests.label-3') }}
                        <span> {{ item.unit.shortname }}</span>
                      </p>
                    </b-col>

                    <b-col cols="2">
                      <p>
                        {{ $t('myrequests.demand') }}
                        <span>{{numberFixer(item.demand)}}</span>
                      </p>
                    </b-col>
                  </b-row>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import deleteInquiry from '@/graphql/me/deleteInquiry.gql';
import deleteProduct from '@/graphql/me/deleteProduct.gql';
export default {
  name: 'product-list-item',

  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      eye: 'https://app.connectter.com/img/eyegray.svg',
      eyeBlue: 'https://app.connectter.com/img/eyeblue.svg',
      editBlue: 'https://app.connectter.com/img/editblue.svg',
      editGray: 'https://app.connectter.com/img/editgray.svg',
      binGray: 'https://app.connectter.com/img/bingray.svg',
      binRed: 'https://app.connectter.com/img/binred.svg',
    };
  },
  computed: {},
  methods: {
    async deleteItem(id) {
      try {
        this.$emit('loading', true);
        if (this.type === 'BUYER') {
          let data = await this.$apollo.mutate(deleteInquiry, {
            id: id,
          });
          let response = data('**.inquiries');
          if (response.delete) {
            this.$emit('deleted', true);
          }
        }
        if (this.type === 'SELLER') {
          let data = await this.$apollo.mutate(deleteProduct, {
            id: id,
          });
          let response = data('**.products');
          if (response.delete) {
            this.$emit('deleted', true);
          }
        }
      } catch (e) {
      } finally {
        this.$emit('loading', false);
      }
    },
  },
};
</script>
