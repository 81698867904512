<template>
  <div class="step-content--extra-outer" style="overflow:hidden;">
    <step-header/>
    <!--jimir3x-->
    <div class="step-content step-content--extra zoom" style="margin-top:100px; ">
      <!--jimir3x-->
     
        <div class="row justify-content-center">
          <loading :loading="loading" />
          <div v-show="!loading" class="col-12">
            <div class="product-list"
            style="    width: 90%;
                      justify-content: center;
                      align-items: center;
                      margin: auto;">
              <div class="product-list-header">
                <h3 class="title text-center">
                  {{ getUserList.total }} / 3
                  {{ UserType === 'BUYER' ? 'Requests' : 'Quotes' }}
                </h3>
                <p class="desc text-center">
                  {{ $t('auth.step3.title') }}
                </p>
              </div>
                <div v-if="!loading" class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-center" style="margin-bottom:60px">
            <router-link
              :to="'/register-steps/step-3/inqury-add'"
              v-if="UserType === 'BUYER' && getUserList.list.length < 3"
              class="add-product-link d-inline-block"
              href="#"
              >{{ $t('buttons.cr') }}
            </router-link>
            <router-link
              :to="'/register-steps/step-3/product-add'"
              v-if="UserType === 'SELLER' && getUserList.list.length < 3"
              class="add-product-link d-inline-block"
              href="#"
              >{{ $t('buttons.cq') }}</router-link
            >
          </div>
        </div>
      </div>
              <div class="product-list-content">
                <product-list-header :type="UserType" />
                <product-item
                  @deleted="getItem"
                  @loading="isLoading"
                  v-for="(item, index) in getUserList.list"
                  :key="index"
                  :type="UserType"
                  :item="item"
                />
              </div>
            </div>
          </div>



          <div v-if="getUserList.list.length === 0 && !loading" class="col-12">
            <div class="alert alert-info text-center">
              You must create a
              {{ UserType === 'buyer' ? 'request' : 'quote' }}, please click
              link the below.
            </div>
          </div>
        </div>
     
    
    </div>
    <step-footer
      @submit="getStep"
      v-if="getUserList.list.length > 0"
      :isBack="false"
      :backStep="'step-2'"
    />
    <b-modal id="modal-center" centered :title="'Confirm'">
      <p class="my-4">
        Eğer eklenen ürün sayısı 5'den küçükse buraya bir uyarı yazısı
        verdireceğiz. Eğer 5 ise yine bir info verebiliriz geri bu adıma
        dönemeyeceğine dair yada direkt diğer adıma geçebiliriz
      </p>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="xs"
            class="float-right"
            @click="confirmStep"
            >{{ $t('buttons.confirm') }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import productItem from '../../components/register-step/products/product-list-item';
import productListHeader from '../../components/register-step/products/list-header';
import stepFooter from '../../components/register-step/step-footer';
import getProducts from '@/graphql/me/getProducts.gql';
import loading from '@/components/global/loading';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import StepHeader from '@/components/register-step/step-header.vue';
export default {
  components: {
    stepFooter,
    productItem,
    loading,
    productListHeader,
    StepHeader,
  },
  data() {
    return {
      loading: false,
      filter: {
        limit: 10,
        filters: {},
        nextCursor: null,
      },
    };
  },
  methods: {
    ...mapMutations({
      setUserList: 'inquiries/setUserList',
      setCompleteStep: 'general/setCompleteStep',
    }),
    ...mapActions({
      fetchInquiriesList: 'inquiries/fetchInquiriesList',
    }),
    getStep() {
      //return this.$root.$emit("bv::show::modal", "modal-center");
      return this.$router.push('/register-steps/step-4');
    },
    confirmStep() {
      this.setCompleteStep(4);
      this.$root.$emit('bv::hide::modal', 'modal-center');
      return this.$router.push('/register-steps/step-4');
    },
    isLoading(val) {
      this.loading = val;
    },
    async getItem(val) {
      if (val) {
        if (this.UserType === 'BUYER') {
          let params = {
            limit: this.filter.limit,
            filters: this.filter.filters,
            cursor: this.filter.nextCursor,
          };
          await this.$store.dispatch('inquiries/fetchInquiriesList', {
            vm: this,
            payload: params,
          });
        }
        if (this.UserType === 'SELLER') {
          return await this.getProducts();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      UserType: 'general/getUserType',
    }),

    getLoading: {
      get() {
        return this.loading;
      },
      set(val) {
        this.loading = val;
      },
    },
  },
  async created() {
    await this.getItem();
  },
};
</script>

<style></style>
