<template>
  <div class="product-list-content-thead">
    <!--<div class="row justify-content-between px-3">
      <div class="col-9">
        <div class="row">
          <div :class="type === 'BUYER' ? 'col-5' : 'col-4'">
            <p v-if="type === 'BUYER'">Product Name & Description</p>
            <p v-else>Product Name & ID</p>
          </div>
          <div v-if="type === 'SELLER'" class="col-3">
            <p>Description</p>
          </div>
          <div class="col-2">
            <p class="text-right">Unit</p>
          </div>
          <div v-if="type === 'BUYER'" class="col-2">
            <p class="text-right">Demand</p>
          </div>
          <div class="col-3">
            <p v-if="type === 'BUYER'" class="text-center">Product Industry</p>
            <p v-else class="text-right">Price Per Unit</p>
          </div>
        </div>
      </div>
    </div> -->

    <b-row v-if="type === 'SELLER' && getUserList.list.length > 0">
      <b-col cols="12" class="request-headers-listing-tags">
        <b-row class="table-columns">
          <b-col cols="3">
            <h6>{{$t("profilex.quotes.label1")}}</h6>
          </b-col>
          <b-col cols="2">
            <h6>{{$t("profilex.quotes.label2")}}</h6>
          </b-col>
          <b-col cols="2">
            <h6>
              {{$t("profilex.quotes.label3")}}
              <!-- <img src="/img/down-arrow.svg" class="img-fluid ml-2" alt="" /> -->
            </h6>
          </b-col>
          <b-col cols="1">
            <h6>
             {{$t("profilex.quotes.label4")}}
            </h6>
          </b-col>
          <b-col cols="2">
            <h6>
              {{$t("profilex.quotes.label5")}}
            </h6>
          </b-col>
          <b-col cols="2"> </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row v-if="type === 'BUYER' && getUserList.list.length > 0">
      <b-col cols="12" class="request-headers-listing-tags">
        <b-row class="table-columns">
          <b-col cols="4">
            <h6>{{$t("profilex.requests.label1")}}</h6>
          </b-col>
          <b-col cols="2" class="pl-10">
            <h6>{{$t("profilex.requests.label2")}}</h6>
          </b-col>
          <b-col cols="2" class="pl-0">
            <h6>
             {{$t("profilex.requests.label3")}}
            </h6>
          </b-col>
          <b-col cols="4" class="pl-0">
            <h6>{{$t("profilex.requests.label4")}}</h6>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "product-list-header",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
